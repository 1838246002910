import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import Slider from "react-slick";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            visible: false,
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            newgamelist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        console.log(44, props);
    }

    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        // console.log(1111111111111111111111111111, this.props.tblcatagoryx[0].System)
        // if( this.props.tblcatagoryx != null)
        // {
        //     this.setState({
        //         System: this.props.tblcatagoryx[0].System
        //     });
        // }

        // await instance.post("/api/v1/mainsetting", {
        //     System: Systems,
        // }).then((res) => {
        //     if (res.data.status === 200) {
        //         this.setState({
        //             System: this.props.tblcatagoryx[0].System
        //         });
        //     }
        //     else {
        //         // this.CheckThisGoalError();
        //     }
        // });
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
            Type: 1
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    // console.log(242, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "7",
            System: Systems,
            Type: 1
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        newgamelist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        await instance.post("/api/v1/tabgamelistpop", {
            state: "5",
            System: Systems,
            playerid: Username

        })
            .then((res) => {
                if (res.data.status === 200) {

                    // this.state.recentmenu
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    //////console.log(555, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await this.checklogin();
    }

    render() {
        const { loginstate } = this.state
        return (
            <>
                <div className="js-replace-cover-seo-container">
                    <div className="x-homepage-banner-container x-banner-slide-wrapper " style={{marginTop:"80px"}}>
                        <Slider {...settings}>
                            <div
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/1.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/2.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/3.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/4.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/1.jpg"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/2.jpg"
                                        />
                                    </picture>
                                </div>
                            </div>
                        </Slider>

                    </div>
                </div>
                <div className="x-index-content-main-container pb-4 -anon " style={{marginTop:"50px"}}>

                    <div className="-provider-logo-top">
                        <div className="x-slot-providers-logo-component -index-top ">
                            <div className="-slider-wrapper -single" data-slickable="{&quot;arrows&quot;:false,&quot;dots&quot;:false,&quot;slidesToShow&quot;:1,&quot;slidesToScroll&quot;:1,&quot;fade&quot;:false,&quot;infinite&quot;:true,&quot;autoplay&quot;:true,&quot;autoplaySpeed&quot;:0,&quot;variableWidth&quot;:true,&quot;swipe&quot;:false,&quot;speed&quot;:3000,&quot;pauseOnHover&quot;:false,&quot;cssEase&quot;:&quot;linear&quot;,&quot;lazyLoad&quot;:&quot;ondemand&quot;}">
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-evo-play-1.png" className="-logo -evo-play" alt="GamefunnyBET evo-play logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-sp-1.png" className="-logo -sp" alt="GamefunnyBET sp logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-ps-1.png" className="-logo -ps" alt="GamefunnyBET ps logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-kingmaker-1.png" className="-logo -kingmaker" alt="GamefunnyBET kingmaker logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-joker-1.png" className="-logo -joker" alt="GamefunnyBET joker logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-habanero-1.png" className="-logo -habanero" alt="GamefunnyBET habanero logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-ameba-1.png" className="-logo -ameba" alt="GamefunnyBET ameba logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-cq9-1.png" className="-logo -cq9" alt="GamefunnyBET cq9 logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-pg-1.png" className="-logo -pg" alt="GamefunnyBET pg logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-rich88-1.png" className="-logo -rich88" alt="GamefunnyBET rich88 logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-goldy-1.png" className="-logo -goldy" alt="GamefunnyBET goldy logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-mg-slot.png" className="-logo -mg-slot" alt="GamefunnyBET mg-slot logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-play-tech.png" className="-logo -play-tech" alt="GamefunnyBET play-tech logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-pragmatic-play.png" className="-logo -pragmatic-play" alt="GamefunnyBET pragmatic-play logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-wm-slot.png" className="-logo -wm-slot" alt="GamefunnyBET wm-slot logo png" />
                                </div>
                                <div className="-slick-item">
                                    <img src="/build/web/ez-bet/img/ez-slot-logo-jili.png" className="-logo -jili" alt="GamefunnyBET jili logo png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-popular-games-list-container" data-animatable="fadeInUp" data-delay={150}>
                        <h2 className="-game-title h5 d-lg-none">Popular Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -best -big">
                                    <div className="-inner-wrapper">
                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-joker/8rqwot18etnuw-vertical.png" src="/build/admin/img/ezs-default-loading-big.png" className="-cover-img lazyload" alt="joker cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">1056 Games</span>
                                            <h2 className="-title">Popular Games</h2>
                                            <a href="สล็อต.html" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.categorylist.map((listitem, i) => (
                                <li key={i} id={listitem.gameid} className="nav-item -first">
                                    <a href={"/listgameall/casino/" + listitem.partner}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener">
                                        <div className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                                            <div className="-inner-wrapper">
                                                <div className="x-game-badge-component -hot -big" data-animatable="fadeInUp" data-delay={400}>
                                                    <span>Hot</span>
                                                </div>
                                                <img src={listitem.imagesec} className="-cover-img lazyload img-fluid" alt="pg-soft cover image png" width={249} height={361} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <button href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" >
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้าเล่น</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-slot-games-list-container" data-animatable="fadeInUp" data-delay={150}>
                        <h2 className="-game-title h5 d-lg-none">Slot Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -slot -big">
                                    <div className="-inner-wrapper">
                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-pg-soft/cover-pg-soft-vertical.png" src="/build/admin/img/ezs-default-loading-big.png" className="-cover-img lazyload" alt="pg-soft cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">653 Games</span>
                                            <h2 className="-title">Slot Games</h2>
                                            <a href="evoplay.html" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.slotlist.map((listitem, i) => (
                                <li key={i} id={listitem.gameid} className="nav-item -first">
                                    <a
                                        href={"/listgameall/slot/" + listitem.partner}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener"
                                    >
                                        <div className="x-game-list-item-macro js-game-list-toggle -big " data-status>
                                            <div className="-inner-wrapper">
                                                <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="evo-play cover image png" width={249} height={361} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <a href={"/listgameall/slot/" + listitem.partner} className="-btn -btn-play">
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้า Lobby</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="-new-games-list-container" data-animatable="fadeInUp" data-delay={100}>
                        <h2 className="-game-title h5 d-lg-none">New<br className="d-none d-lg-block" /> Games</h2>
                        <ul className="navbar-nav">
                            <li className="nav-item d-none d-lg-inline-block">
                                <div className="x-game-list-heading-macro -new -normal">
                                    <div className="-inner-wrapper">
                                        <img data-src="https://asset.cloudigame.co/build/admin/img/ez-ps/PSS-ON-00129-square.png" src="/build/admin/img/ezs-default-loading-normal.png" className="-cover-img lazyload" alt="ps cover image png" />
                                        <div className="-overlay">
                                            <span className="-length">600 Games</span>
                                            <h2 className="-title">New<br className="d-none d-lg-block" /> Games</h2>
                                            <a href="games.html" className="-seemore-btn">All games</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {this.state.newgamelist.map((listitem, i) => (
                                <li className="nav-item " key={i}>
                                    <a
                                        href={() => false}
                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                        className="nav-link js-account-approve-aware"
                                        rel="nofollow noopener"
                                    >
                                        <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                            <div className="-inner-wrapper">
                                                <div className="x-game-badge-component -new -normal" data-animatable="fadeInUp" data-delay={400}>
                                                    <span>New</span>
                                                </div>
                                                <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="rich88 cover image png" width={216} height={216} />
                                                <div className="-overlay">
                                                    <div className="-overlay-inner">
                                                        <div className="-wrapper-container">
                                                            <button href={() => false} className="-btn -btn-play js-account-approve-aware">
                                                                <i className="fas fa-play" />
                                                                <span className="-text-btn">เข้าเล่น</span>
                                                            </button>
                                                            <button
                                                                id="loginmode"
                                                                href="#loginModal"
                                                                className="-btn -btn-play js-account-approve-aware"
                                                                data-toggle="modal"
                                                                data-target="#loginModal"
                                                                style={{ display: "none" }}
                                                            >
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="-title">{listitem.description}</div>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-games-splitter-zone-container -anon" data-animatable="fadeInUp" data-delay={250}>
                        <div className="-left-side">
                            <div className="-live-casino-container" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="-game-title h5 d-lg-none">Live<br className="d-none d-lg-block" /> Casino</h2>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-none d-lg-inline-block">
                                        <div className="x-game-list-heading-macro -live-casino -normal">
                                            <div className="-inner-wrapper">
                                                <img data-src="https://asset.cloudigame.co/build/admin/img/sa-gaming/ezs-sa-gaming-square.png" src="/build/admin/img/ezs-default-loading-normal.png" className="-cover-img lazyload" alt="sa-gaming cover image png" />
                                                <div className="-overlay">
                                                    <span className="-length">15 Games</span>
                                                    <h2 className="-title">Live<br className="d-none d-lg-block" /> Casino</h2>
                                                    <a href="casinos.html" className="-seemore-btn">All games</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.categorylist.slice(0, 5).map((listitem, i) => (
                                        <li className="nav-item -first" key={i}>
                                            <a
                                                href={"/listgameall/slot/" + listitem.partner}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div className="x-game-list-item-macro js-game-list-toggle -normal " data-status>
                                                    <div className="-inner-wrapper">
                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="sa-gaming cover image png" width={216} height={216} />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <a href={"/listgameall/casino/" + listitem.partner} className="-btn -btn-play js-account-approve-aware">
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-title">{listitem.description}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="-sports-container" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="-game-title h5 d-lg-none">Sport<br className="d-none d-lg-block" /> Games</h2>
                                <ul className="navbar-nav">
                                    <li className="nav-item d-none d-lg-inline-block">
                                        <div className="x-game-list-heading-macro -sport -normal">
                                            <div className="-inner-wrapper">
                                                <img data-src="https://asset.cloudigame.co/build/admin/img/wt-saba/ezs-wt-saba-square.png" src="/build/admin/img/ezs-default-loading-normal.png" className="-cover-img lazyload" alt="wt-saba cover image png" />
                                                <div className="-overlay">
                                                    <span className="-length">4 Games</span>
                                                    <h2 className="-title">Sport<br className="d-none d-lg-block" /> Games</h2>
                                                    <a href="sports.php" className="-seemore-btn">All games</a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.recentlist.slice(0, 5).map((listitem, i) => (
                                        <li className="nav-item -first" key={i}>
                                            <a
                                                href={"/listgameall/sport/" + listitem.partner}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div className="x-game-list-item-macro js-game-list-toggle -normal -cannot-entry -untestable" data-status="-cannot-entry -untestable">
                                                    <div className="-inner-wrapper">
                                                        <img src={listitem.image} className="-cover-img lazyload img-fluid" alt="pinnacle cover image png" width={216} height={216} />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <button href={"/listgameall/sport/" + listitem.partner} className="-btn -btn-play js-account-approve-aware" data-toggle="modal" data-target="#loginModal">
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="-title">{listitem.description}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="-right-side">
                            <a href="listgameall/casino/" className="-button-wrapper" data-animatable="fadeInUp" data-delay={100}>
                                <h2 className="text-center">คาสิโนสดชั้นนำ</h2>
                                <img data-src="/build/web/ez-bet/img/ez-slot-girl-china.png" className="-china-girl lazyload" alt="Ezbet china girl image png" />
                                <img data-src="/build/web/ez-bet/img/ez-slot-dice-dual.png" className="-dice-dual lazyload" alt="Ezbet two dices image png" />
                            </a>
                        </div>
                    </div>
                    <div className="-promotion-section-wrapper -anon" data-animatable="fadeInUp" data-delay={350}>
                        <div className="-big-win-container">
                            <div className="x-big-win-component">
                                <img className="-bg-big-win d-none d-lg-block lazyload" data-src="/build/web/ez-bet/img/ez-slot-bg-bigwin-desktop.png" alt="รูปเนื้อหา Bigwin" />
                                <img className="-thumbles d-none d-lg-block lazyload" data-src="/build/web/ez-bet/img/ez-slot-thumbles.gif" alt="รูปตัวอย่างเกมส์" />
                                <div className="-poringwing-animate d-none d-lg-block lazyload" data-animation-lottie="/build/web/ez-bet/animations/poringwing.json"></div>

                                <div className="-bonus-win" >
                                    <div className="-win-anima" data-animation-lottie="/build/web/ez-bet/animations/bigwin.json"></div>
                                    <a href="#0" data-toggle="modal" data-target="#registerModal" className="btn -register-btn">
                                        สมัครสมาชิก
                                    </a>
                                    <img src="/build/web/ez-bet/img/ez-slot-coin-01.png" className="-coin -coin-1" alt="GamefunnyBET รูปเหรียญทอง png" />
                                    <img src="/build/web/ez-bet/img/ez-slot-coin-02.png" className="-coin -coin-2" alt="GamefunnyBET รูปเหรียญทอง png" />
                                    <img src="/build/web/ez-bet/img/ez-slot-coin-03.png" className="-coin -coin-3" alt="GamefunnyBET รูปเหรียญทอง png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="-status-game-section-wrapper -anon">
                        <div className="container">
                            <ul className="-status-game-container-wrapper">
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={100}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-wallet.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">Smart Wallet</h3>
                                        <span className="-status-detail d-none d-md-block">เล่นได้ทุกเกมส์ไม่ต้องโยกเงิน</span>
                                    </div>
                                </li>
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={200}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-rank.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">อันดับ 1 ในไทย</h3>
                                        <span className="-status-detail d-none d-md-block">เทคโนโลยี และเกมส์จากทั่วทุกมุมโลก</span>
                                    </div>
                                </li>
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={300}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-change.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">ฝาก - ถอน เร็วมาก</h3>
                                        <span className="-status-detail d-none d-md-block">ฝาก-ถอนเร็วมากด้วยระบบ Bot AI</span>
                                    </div>
                                </li>
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={400}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-eveywhere.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">เล่นได้ทุกที่</h3>
                                        <span className="-status-detail d-none d-md-block">ระบบใช้ง่ายรองรับทุกอุปกรณ์</span>
                                    </div>
                                </li>
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={500}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-support.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">บริการด้วยใจ</h3>
                                        <span className="-status-detail d-none d-md-block">ฝ่ายบริการลูกค้า24 ชม ทุกวัน</span>
                                    </div>
                                </li>
                                <li className="-status-list" data-animatable="fadeInUp" data-delay={600}>
                                    <img className="-status-img" src="/build/web/ez-bet/img/ez-slot-status-promotion.png" alt="Pgline" />
                                    <div className="-status-content">
                                        <h3 className="-status-title h5">โปรดีๆสำหรับคุณ</h3>
                                        <span className="-status-detail d-none d-md-block">กิจกรรมและโปรโมชั่นหลากหลายแรงที่สุดในไทย</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
